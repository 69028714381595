import { Checkbox, DatePicker, Form, FormInstance, Input, InputNumber, Spin, TimePicker } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs';

import { CardBox } from './cardBox';
import CustomSelect from 'base/ui/components/CustomSelect';
import { PurchaseOrderModel } from 'models/PurchaseOrder';
import { SearchItem } from 'pages/Components/SearchBox';
import { listSangChieu } from 'pages/rawData/Permission/model';
import { sFormatNumber } from 'utils/formatHelper';
import { services } from 'stores/root_store';
import { getRole } from 'utils/localStorageHelper';

type GeneralInformationProps = {
    disabledField: () => boolean;
    deliveryRegistrationId?: string;
    role: string;
    accessUpdated: boolean;
    formCreate: FormInstance<any>;
    totalDeliveredQty: { total: number; itemsTotal: number }
    dataFromList?: PurchaseOrderModel[],
    setTotalDeliveryQty: Dispatch<SetStateAction<{
        total: number;
        itemsTotal: number;
    }>>
    vendorNumber: string | undefined
}

const GeneralInformation: React.FC<GeneralInformationProps> = (props) => {
    let period = props.formCreate.getFieldsValue();
    // console.log({ period })

    const {
        accessUpdated,
        disabledField,
        role,
        deliveryRegistrationId,
        formCreate,
        totalDeliveredQty,
        dataFromList,
        setTotalDeliveryQty,
        vendorNumber
    } = props

    const [loading, setLoading] = useState(false);
    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);
    // const handleCheckboxChange = async (e: any) => {
    //     const isChecked = e.target.checked;
    //     if (isChecked) {
    //         setLoading(true);
    //         const data = await services.vendorServices.checkEmailVendor(vendorNumber || "")
    //         if (data?.data?.isHasMail) {
    //             formCreate.setFields([
    //                 {
    //                     name: 'isSendEmail',
    //                     errors: []
    //                 }
    //             ]);
    //         } else {
    //             formCreate.setFields([
    //                 {
    //                     name: 'isSendEmail',
    //                     errors: ['Nhà cung cấp chưa có thông tin email. Vui lòng kiểm tra lại']
    //                 }
    //             ]);
    //         }
    //         setLoading(false);

    //     } else {
    //         formCreate.setFields([
    //             {
    //                 name: 'isSendEmail',
    //                 errors: []
    //             }
    //         ]);
    //     }
    // }

    // onChange={handleCheckboxChange}
    // 

    useEffect(() => {
        // Nếu PP vào trang chỉnh sửa phiếu, thì luôn check cái isSendEmail checkbox này
        const role = getRole();
        // console.log({ deliveryRegistrationId, role })
        if (deliveryRegistrationId && role === "PP") {
            formCreate.setFieldsValue({ isSendEmail: true })
        } else if (deliveryRegistrationId && role !== "PP") {
            formCreate.setFieldsValue({ isSendEmail: false });
            setIsCheckboxDisabled(true);
        }

    }, [deliveryRegistrationId, formCreate]);
    return (
        <CardBox title="Thông tin chung" >
            <div >
                {/* gửi mail */}
                <Spin spinning={loading}>
                    <SearchItem initialValue={false} name={'isSendEmail'} valuePropName="checked" rules={[
                        {
                            validator: async (_, value) => {
                                if (value) {
                                    setLoading(true);
                                    const data = await services.vendorServices.checkEmailVendor(vendorNumber || "");
                                    setLoading(false);
                                    if (!data?.data?.isHasMail) {
                                        return Promise.reject(new Error('Nhà cung cấp chưa có thông tin email. Vui lòng kiểm tra lại'));
                                    }
                                }
                            }
                        }
                    ]} >
                        <Checkbox disabled={disabledField() || isCheckboxDisabled}>Hệ thống tự động gửi mail cho nhà cung cấp</Checkbox>
                    </SearchItem>
                </Spin>
                {/* Mã nhà cung cấp */}
                <SearchItem name='vendorNumber' hidden >
                    <Input />
                </SearchItem>
                {/* nhà cung cấp */}
                <SearchItem name={'vendorName'} label='Nhà cung cấp'>
                    <Input disabled />
                </SearchItem>
                {/* Tình trạng phiếu */}
                {deliveryRegistrationId && <SearchItem name={'taskStatusName'} label='Tình trạng phiếu'>
                    <Input disabled />
                </SearchItem>}
                {/* Thời gian giao hàng */}
                <SearchItem required={true} label='Thời gian giao hàng'>
                    <Form.List name='deliveryTimeItems' rules={[{
                        validator: async (_, value) => {
                            if (value && value.length) {
                                const total = value.reduce(
                                    (sum: number, item: { time?: Dayjs; deliveryQuantity?: number }) => sum + (item?.deliveryQuantity || 0),
                                    0
                                );
                                if (total > totalDeliveredQty.total) {
                                    return Promise.reject(new Error('Tổng số lượng hàng giao theo từng đợt không khớp với tổng số lượng đã đăng ký.'));
                                }
                            }
                        },
                    }]}>
                        {(fiels, { add, remove }, { errors }) => <div className="flex flex-col gap-3">
                            {fiels.map((fiel, index) => (
                                <> <div key={index} className="w-full flex gap-default items-start justify-">
                                    {/* thời gian giao */}
                                    <SearchItem hidden name={[fiel.name, 'deliveryTime']}>
                                        <Input />
                                    </SearchItem>
                                    {/* id đợt giao hàng */}
                                    <SearchItem hidden name={[fiel.name, 'deliveryRegistrationTimeId']}>
                                        <Input />
                                    </SearchItem>
                                    <div className="flex w-full">
                                        {/* hiện ngày giao hàng */}
                                        <SearchItem name={[fiel.name, 'day']} noStyle rootClassName="!w-28">
                                            <DatePicker style={{ width: '100%' }} format={'DD/MM/YYYY'}
                                                // PP được phép chỉnh sửa ngày giao hàng
                                                disabled={!(role === "PP" && accessUpdated)}
                                                allowClear={false}
                                                onChange={(value) => {
                                                    formCreate.setFieldValue(['deliveryTimeItems', fiel.name, 'deliveryTime'],
                                                        `${value?.format("YYYY-MM-DD")}T${dayjs(formCreate.getFieldValue(['deliveryTimeItems', fiel.name, 'time']))?.format("HH")}:00:00`)
                                                }}

                                                className="!rounded-r-none" />
                                        </SearchItem>
                                        {/* giờ giao hàng */}
                                        <SearchItem
                                            name={[fiel.name, 'time']}

                                            rootClassName="flex-1"
                                        >
                                            <TimePicker
                                                disabled={disabledField()}
                                                onChange={(value) => {
                                                    formCreate.setFieldValue(['deliveryTimeItems', fiel.name, 'deliveryTime'],
                                                        `${!deliveryRegistrationId ?
                                                            dayjs(dataFromList?.at(0)?.documentDate).format("YYYY-MM-DD") :
                                                            dayjs(formCreate.getFieldValue(['deliveryTimeItems', fiel.name, 'deliveryTime'])).format('YYYY-MM-DD')
                                                        }T${value?.format("HH")}:00:00`)
                                                }}

                                                changeOnBlur
                                                className="w-full !rounded-l-none" format={"hh A"}
                                                placeholder="hh:mm A"

                                            />
                                        </SearchItem >
                                        <SearchItem
                                            name={[fiel.name, 'periodTime']}
                                            rules={[{ required: true, message: 'Vui lòng chọn Thời gian giao hàng' }]}
                                            rootClassName="flex-1"
                                        >
                                            <CustomSelect
                                                disabled={disabledField()}
                                                // disabled={!(role === "PP" && accessUpdated)}
                                                options={listSangChieu}
                                                allowClear
                                                showSearch
                                            //className={`w-full font-[400] text-[14px] m-0 !min-w-[240px] `}
                                            />
                                        </SearchItem >

                                    </div>

                                    {fiels.length > 1 && (
                                        <SearchItem
                                            name={[fiel.name, 'deliveryQuantity']}
                                            className="!w-full" rootClassName="w-1/3"
                                            rules={[{ required: true, message: "Vui lòng nhập số lượng." }, { type: 'number', min: 1, message: "Số lượng phải lớn hơn 0!" }]}
                                        >
                                            <InputNumber<number>
                                                formatter={(value) => {
                                                    return sFormatNumber(Number(value)) as string;
                                                }}
                                                disabled={disabledField()}
                                                onBlur={(e) => {
                                                    const currentValues = formCreate.getFieldValue('deliveryTimeItems');
                                                    const newValues = [...currentValues];
                                                    const total = newValues.reduce(
                                                        (sum: number, item: { time?: typeof dayjs; deliveryQuantity?: number }) => sum + (item?.deliveryQuantity || 0),
                                                        0
                                                    );
                                                    setTotalDeliveryQty(prev => ({ ...prev, itemsTotal: total }))
                                                }}
                                                className="!w-full" placeholder="Số lượng giao hàng" />
                                        </SearchItem >
                                    )}
                                    {/* {index === 0 ? (
                            <Button
                                disabled={disabledField()} type="link"
                                icon={<TbCirclePlus
                                    className={`text-xl ${(() => {
                                        if (totalDeliveredQty.total > 0 && totalDeliveredQty.total > totalDeliveredQty.itemsTotal)
                                            if (deliveryRegistrationId) {
                                                if (!disabledField())
                                                    return "cursor-pointer text-teal-500 "
                                            } else {
                                                return "cursor-pointer text-teal-500 "
                                            }
                                        return "cursor-not-allowed text-gray-400 "
                                    })()}`}
                                />}
                                onClick={() => {
                                    if (totalDeliveredQty.total > 0 && totalDeliveredQty.total > totalDeliveredQty.itemsTotal) {
                                        if (deliveryRegistrationId) {
                                            const documentDate = dataDetail?.deliveryTimeItems?.at(0)?.deliveryTime
                                            add({ day: documentDate && dayjs(documentDate) })
                                        } else {
                                            const documentDate = dataFromList?.at(0)?.documentDate
                                            add({ day: documentDate && dayjs(documentDate) })
                                        }
                                    }
                                }} >
                            </Button>) :
                            <Button type="link"
                                disabled={disabledField()}
                                icon={<TbCircleMinus
                                    className={`text-xl ${(() => {
                                        if (!disabledField())
                                            return "cursor-pointer text-red-500 "
                                        return "cursor-not-allowed text-gray-400 "
                                    })()}`}
                                />}
                                onClick={() => {
                                    const currentValues = formCreate.getFieldValue(['deliveryTimeItems', fiel.name, 'deliveryQuantity']);
                                    setTotalDeliveryQty(prev => ({ ...prev, itemsTotal: prev.itemsTotal - (Number(currentValues) || 0) }))
                                    remove(index)
                                }} >
                            </Button>} */}
                                </div>
                                    <SearchItem rules={[{ required: true, message: "Vui lòng chọn thời gian giao hàng" }]}>

                                        <span className='italic text-red-400 flex-wrap'>Giờ làm việc: Sáng: 06h00 - 11h00</span>
                                        <div className='italic text-red-400 block items-end ml-[75px]'>Chiều: 12h30 - 20h00</div>
                                    </SearchItem>
                                </>

                            ))}
                            <Form.ErrorList errors={errors} />
                        </div>}
                    </Form.List>
                </SearchItem>
            </div>
        </CardBox>
    )
}

export default GeneralInformation