import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Input, InputNumber, Modal, Row, Space, message } from "antd";
import { FormInstance } from "antd/lib/form";
import dayjs, { Dayjs } from "dayjs";
import { SearchItem } from "pages/Components/SearchBox";
import React, { useEffect } from "react";
import { CgAsterisk } from "react-icons/cg";
import { FaAsterisk } from "react-icons/fa";
import { formatDecimalNumber, sFormatNumber } from "utils/formatHelper";
import { HiOutlinePlusCircle } from "react-icons/hi2";
import { HiOutlineMinusCircle } from "react-icons/hi";
import { cloneDeep } from "lodash";


export interface RowData {
  date: Dayjs | null;
  quantity: number;
}

interface ModalNSXProps {
  editTableForm: FormInstance;
  onClose: () => void; // Thêm onClose prop để đóng modal
  onSave: (rowsData: { [key: string]: RowData[] }) => void; // Thêm onSave prop để xử lý lưu
  rowsData: { [key: string]: RowData[] };
  setRowsData: React.Dispatch<React.SetStateAction<{ [key: string]: RowData[] }>>;
  formCreate: FormInstance
  isRemoveAndAddNewColumn: boolean;
  deliveryRegistrationId: string | undefined;
}

export const roundToThreeDecimals = (num: number | undefined) => num ? (Math.round(num * 1000) / 1000) : 0;

const ModalNSX: React.FC<ModalNSXProps> = ({ editTableForm, onClose, onSave, rowsData, setRowsData, formCreate, isRemoveAndAddNewColumn, deliveryRegistrationId, }) => {

  const handleSave = () => {
    console.warn("handle save run")
    // Lấy dữ liệu từ form
    const fieldsValue = editTableForm.getFieldsValue();

    let isValid = true;
    let errorMessage = '';




    // Kiểm tra tổng quantity có bằng deliveredQuantity không
    for (const key in rowsData) {
      const rowData = rowsData[key];
      const deliveredQuantity = fieldsValue[key]?.deliveredQuantity || 0;

      // Function to round a number to 3 decimal places

      // const totalQuantity = rowData.reduce((sum, row) => sum + row.quantity, 0);
      // const totalQuantity = rowData.reduce((sum, row) => sum + row.quantity * 100, 0) / 100; // làm tròn số
      // Calculate totalQuantity, round it to 3 decimals
      const totalQuantity = rowData.reduce((sum, row) => sum + row.quantity, 0);

      if (roundToThreeDecimals(totalQuantity) !== roundToThreeDecimals(deliveredQuantity)) {
        isValid = false;
        const rowIndex = Object.keys(rowsData).indexOf(key); // Lấy chỉ số dòng (index)
        errorMessage = `Tổng số lượng của "Dòng ${rowIndex + 1}" không khớp với Số lượng giao hàng: ${formatDecimalNumber(`${sFormatNumber(deliveredQuantity)}`)}`;
        break;
      }
    }

    // Kiểm tra các ngày sản xuất có lớn hơn deliveryTime không
    if (isValid) {
      const deliveryTime = formCreate.getFieldsValue().deliveryTimeItems[0]?.deliveryTime;
      if (!deliveryTime) {
        isValid = false;
        errorMessage = 'Vui lòng nhập thời gian giao hàng.';
      } else {
        for (const key in rowsData) {
          for (const row of rowsData[key]) {
            const date = row.date ? dayjs(row.date) : null;
            if (date && date.isAfter(dayjs(deliveryTime), 'day')) {
              isValid = false;
              const rowIndex = Object.keys(rowsData).indexOf(key); // Lấy chỉ số dòng (index)
              errorMessage = `Ngày sản xuất của "Dòng ${rowIndex + 1}" không được nhập lớn hơn ngày giao hàng. Vui lòng kiểm tra lại!`;
              break;
            }
          }
          if (!isValid) break;
        }
      }
    }

    // Kiểm tra date không được trống và quantity phải > 0
    if (isValid) {
      for (const key in rowsData) {
        for (const row of rowsData[key]) {
          if (!row.date) {
            isValid = false;
            const rowIndex = Object.keys(rowsData).indexOf(key); // Lấy chỉ số dòng (index)
            errorMessage = `Ngày sản xuất của "Dòng ${rowIndex + 1}" không được để trống.`;
            break;
          }

          if (row.quantity <= 0) {
            isValid = false;
            const rowIndex = Object.keys(rowsData).indexOf(key); // Lấy chỉ số dòng (index)
            errorMessage = `Số lượng trong "Dòng ${rowIndex + 1}" phải lớn hơn 0.`;
            break;
          }
        }
        if (!isValid) break;
      }
    }

    if (isValid) {

      onSave(rowsData); // Nếu tất cả validation đều ok, gọi hàm lưu
    } else {
      message.error(errorMessage); // Hiển thị thông báo lỗi
    }
  };

  useEffect(() => {
    const fieldsValue = editTableForm.getFieldsValue();

    // Chỉ set default value nếu lần đầu mở Thêm/sửa ngày sản xuất
    // Kiểm tra xem prodDate và qtyDeliveredPerProdDate có giá trị không
    const initialRowsData = Object.keys(fieldsValue).reduce((acc, key) => {
      const field = fieldsValue[key];

      // Kiểm tra prodDate và qtyDeliveredPerProdDate có giá trị không

      // nếu 2 cột prodDate và qtyPerProdDate chưa bị remove thì set giá trị default
      if (!isRemoveAndAddNewColumn) {
        const rowData = [{
          date: field.prodDate ? dayjs(field.prodDate) : null, // Chuyển đổi prodDate thành dayjs nếu có
          quantity: field.qtyDeliveredPerProdDate || 0, // Nếu qtyDeliveredPerProdDate không có, dùng 0
        }];
        acc[key] = rowData;
      }

      return acc;
    }, {} as { [key: string]: RowData[] });

    // Cập nhật rowsData chỉ khi có giá trị
    if (Object.keys(initialRowsData).length > 0) {
      setRowsData(initialRowsData);
    }
  }, [editTableForm]);


  const handleAddRow = (key: string): void => {
    setRowsData((prevRowsData) => ({
      ...prevRowsData,
      [key]: [...prevRowsData[key], { date: null, quantity: 0 }],
    }));
  };

  const handleRemoveRow = (key: string, rowIndex: number): void => {
    setRowsData((prevRowsData) => {
      const updatedRows = prevRowsData[key].filter((_, i) => i !== rowIndex);
      return { ...prevRowsData, [key]: updatedRows };
    });
  };

  const handleChangeDate = (date: Dayjs | null, key: string, rowIndex: number): void => {
    const updatedRows = [...rowsData[key]];
    updatedRows[rowIndex].date = date;
    setRowsData((prevRowsData) => ({ ...prevRowsData, [key]: updatedRows }));
  };

  const handleChangeQuantity = (value: number | null, key: string, rowIndex: number): void => {
    const updatedRows = [...rowsData[key]];
    updatedRows[rowIndex].quantity = value || 0;
    setRowsData((prevRowsData) => ({ ...prevRowsData, [key]: updatedRows }));
  };

  return (
    <Modal
      title={<p className="text-base font-semibold">THÊM NGÀY SẢN XUẤT</p>}
      visible={true}
      onCancel={() => {
        if (!isRemoveAndAddNewColumn) {
          // khi nhấn dấu X đóng modal thì sẽ xóa data trong RowsData, để khi nhấn save ở ngoài thì nó dùng data trong 2 cột ở ngoài mà ko dùng data của RowsData. Chỉ làm điều này khi chưa remove 2 cột ở ngoài. Nếu 2 cột ở ngoài bị remove rồi thì ẩn dấu X ko cho tắt luôn
          setRowsData({})
        }
        onClose()
      }} // Đóng modal khi nhấn "X" hoặc ngoài modal
      maskClosable={false} // Ngừng đóng modal khi click ngoài
      // closable={!(isRemoveAndAddNewColumn || !!deliveryRegistrationId)}
      footer={
        <Button
          onClick={handleSave} // Xử lý lưu khi nhấn nút Lưu
          type="primary"
          htmlType="button"
        >
          Lưu
        </Button>
      }
    >
      {
        Object.entries(rowsData).map(([key, data], index) => (
          <div key={key} className="mb-5">
            {/* <div className="font-medium mb-1 text-sm">Dòng </div> */}
            <div className="flex mb-3">
              <SearchItem label={"Dòng"} >
                <Input value={index + 1} disabled size="small" />
              </SearchItem>
            </div>
            <Row gutter={16} className="font-medium ">
              <Col span={8}>
                <p className="flex gap-[2px] items-center"> <CgAsterisk size={14} color="red" />Ngày sản xuất</p>
              </Col>
              <Col span={8}>
                <p className="flex gap-[2px] items-center"> <CgAsterisk size={14} color="red" />Số lượng</p>
              </Col>
            </Row>
            <div className="flex flex-col gap-1">
              {data?.map((row, rowIndex) => (
                <Row key={rowIndex} gutter={16} >
                  <Col span={8}>
                    <DatePicker

                      value={row.date ? dayjs(row.date) : null}
                      onChange={(date) => handleChangeDate(date, key, rowIndex)}
                      style={{ width: "100%" }}
                      format="DD/MM/YYYY"
                    />
                  </Col>
                  <Col span={8}>
                    <InputNumber
                      controls={false}
                      // value={row.quantity}
                      value={row.quantity === 0 ? null : row.quantity}
                      onChange={(value) => handleChangeQuantity(value, key, rowIndex)}
                      required
                      // formatter={(value, info) => {
                      //   if (value) {
                      //     return sFormatNumber(Number(value)) as string;
                      //   } else return ''
                      // }}
                      formatter={(value, info) => {
                        if (value) {
                          if (info.userTyping) {
                            return sFormatNumber(Number(value)) as string;
                          } else {
                            return formatDecimalNumber(`${sFormatNumber(Number(value))}`)
                          }
                        } else return ''
                      }}
                      // formatter={(value, info) => {
                      //   if (value) {
                      //     return sFormatNumber(Number(value)) as string;
                      //   } else return ''
                      // }}
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                    <Space>
                      {rowIndex === 0 && <Button
                        icon={<HiOutlinePlusCircle className="text-green-500" size={20} />}
                        onClick={() => handleAddRow(key)}
                        // type="primary"
                        size="small"
                      />}
                      {rowIndex !== 0 && rowsData[key]?.length > 1 && (
                        <Button
                          icon={<HiOutlineMinusCircle className="text-red-500" size={20} />}
                          onClick={() => handleRemoveRow(key, rowIndex)}
                          // danger
                          size="small"
                        />
                      )}
                    </Space>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        ))
      }
    </Modal>
  );
};

export default ModalNSX;


